<template>
  <el-scrollbar style="height: 100%">
    <div class="course-box">
      <div class="top-bar">
        <div class="left">
          脚本案例——商品类
        </div>
        <div class="right">
          <div class="title flex justify-between">
            <span>脚本名称：商品类短视频脚本</span>
            <el-button type="primary" style="margin-bottom: 20px" @click="$router.go(-1)">返回</el-button>
          </div>
          <div class="second">脚本分类：电商</div>
          <div class="second">脚本简介：这是简介这是简介这是简介这是简介这是简介这是简介</div>
        </div>
      </div>
      <div class="course-table">
        <div class="tr th">
          <div class="td first">镜头</div>
          <div class="td flex-1">景别</div>
          <div class="td flex-1">拍摄方向</div>
          <div class="td flex-1">运镜</div>
          <div class="td flex-2">拍摄内容</div>
          <div class="td flex-1">音乐/字幕</div>
          <div class="td flex-1">时间</div>
        </div>
        <div class="tbody">
          <div class="tr" v-for="(item, index) in list" :key="`list_${index}`">
            <div class="td first">{{ item.rank }}</div>
            <div class="td flex-1">{{ item.view }}</div>
            <div class="td flex-1">{{ item.direction }}</div>
            <div class="td flex-1">{{ item.view1 }}</div>
            <div class="td flex-2">{{ item.content }}</div>
            <div class="td flex-1">{{ item.music }}</div>
            <div class="td flex-1">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          rank: 1,
          view: '全景',
          direction: '正面拍摄',
          view1: '/',
          content: '展示收纳盒上化妆镜及照明功能',
          music: '音乐开始',
          time: '3s',
        },
        {
          rank: 2,
          view: '全景',
          direction: '正面拍摄',
          view1: '定格',
          content: '展示收纳盒的收纳功能 (收纳化妆品，上层置物区收纳瓶子等较高化妆品、中间左侧抽屉以及中间右侧抽屉收纳口红等化妆品、底层抽屉收纳 面膜)',
          music: '音乐开始',
          time: '6s',
        },
        {
          rank: 3,
          view: '全景',
          direction: '正面拍摄',
          view1: '特写切全景',
          content: '特写商品，再通过推动商品使画面产生运动感，实际上镜头位置不变',
          music: '音乐开始',
          time: '2s',
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.course-box {
  padding: 30px;
  .top-bar {
    display: flex;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      width: 288px;
      height: 179px;
      background: url("../../../assets/image/teacherDataCenter/course-bg.png") no-repeat;
      background-size: cover;
    }
    .right {
      padding: 16px 0;
      margin-left: 20px;
      width: 1%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 20px;
      }
      .second {
        color: #666;
      }
    }
  }
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.course-table {
  margin-top: 10px;
  color: #232324;
  .tr {
    display: flex;
    text-align: center;
    background: #F0F3FF;
    border-bottom: 2px solid #fff;
    &:last-child {
      border-bottom: none;
    }
    .td {
      border-right: 2px solid #fff;
      padding: 15px;
      &.first {
        width: 50px;
      }
      &:last-child {
        border-right: none;
      }
    }
    &.th {
      background: #D3DDFF;
    }
  }
}
</style>